var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("All Product Groups")])]),_c('CCardBody',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Description")]),(
                    _vm.can('product_group_read') ||
                    _vm.can('product_group_update') ||
                    _vm.can('product_group_delete')
                  )?_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Action ")]):_vm._e()])]),(_vm.isLoading)?_c('tbody',{staticClass:"text-center pt-5"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"8"}},[_c('CSpinner',{attrs:{"color":"primary","size":"lg"}})],1)])]):_c('tbody',_vm._l((_vm.product_groups),function(product_group){return _c('tr',{key:product_group.id},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(product_group.name)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(product_group.description))]),(
                    _vm.can('product_group_read') ||
                    _vm.can('product_group_update') ||
                    _vm.can('product_group_delete')
                  )?_c('td',{staticClass:"text-center"},[_c('CButtonGroup',{attrs:{"size":"sm"}},[(_vm.can('product_group_update'))?_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.editProductGroup(product_group.id)}}},[_vm._v(" Edit")]):_vm._e(),(_vm.can('product_group_delete'))?_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteProductGroup(_vm.deleteModal, product_group)}}},[_vm._v("Delete")]):_vm._e()],1)],1):_vm._e()])}),0)]),(!_vm.isLoading)?_c('CPagination',{attrs:{"activePage":_vm.pagination.current,"pages":_vm.pagination.last,"align":"center"},on:{"update:activePage":[function($event){return _vm.$set(_vm.pagination, "current", $event)},_vm.getResults],"update:active-page":function($event){return _vm.$set(_vm.pagination, "current", $event)}}}):_vm._e()],1)])],1)],1),_c('ProductGroupDelete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }