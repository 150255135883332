<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Product Groups</strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Description</th>

                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('product_group_read') ||
                      can('product_group_update') ||
                      can('product_group_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="product_group in product_groups"
                  :key="product_group.id"
                >
                  <td class="text-left">
                    {{ product_group.name }}
                  </td>
                  <td class="text-left">{{ product_group.description }}</td>
                  <td
                    class="text-center"
                    v-if="
                      can('product_group_read') ||
                      can('product_group_update') ||
                      can('product_group_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="info"
                        @click="editProductGroup(product_group.id)"
                        v-if="can('product_group_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteProductGroup(deleteModal, product_group)"
                        color="danger"
                        v-if="can('product_group_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>

    <ProductGroupDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const ProductGroupDelete = () =>
  import("@/views/ProductGroup/ProductGroupDelete");
export default {
  name: "ProductGroups",
  components: {
    ProductGroupDelete,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("product_groups/getProductGroups");
  },
  computed: {
    ...mapState({
      pagination: (state) => state.product_groups.pagination,
      product_groups: (state) => state.product_groups.product_groups,
      isLoading: (state) => state.product_groups.isLoading,
      deleteModal: (state) => state.product_groups.deleteModal,
    }),
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("product_groups/getProductGroups", page);
    },
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    deleteProductGroup(bool, product_group) {
      this.$store.commit("product_groups/DELETE_MODAL", {
        bool: bool,
        product_group: product_group,
      });
    },

    editProductGroup(product_group_id) {
      this.$store.dispatch("product_groups/editProductGroup", {
        product_group_id: product_group_id,
      });
      this.$router.push({ name: "Edit Product Group" });
    },
  },
};
</script>
